import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
const useStyle = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));
const NextArrow = (props) => {
  const { className, style, onClick, down } = props;
  const classes = useStyle();
  return (
    <KeyboardBackspaceIcon
      className={clsx(className, classes.root)}
      onClick={onClick}
      style={{
        ...style,
        transform: down ? "rotate(270deg)" : "rotate(180deg)",
      }}
      fontSize="large"
    />
  );
};
export default NextArrow;
