import { ApolloError, useQuery } from "@apollo/client";
import { useMemo } from "react";
import COURSES_QUERY from "src/graphql/query/courses";

interface CoursesTypes {
  id: string;
  name: string;
}
interface CoursesTypesData {
  courses: CoursesTypes[];
}

const useCourses: () => {
  courses: CoursesTypes[];
  loading: boolean;
  error: ApolloError;
} = () => {
  const { data, error, loading } = useQuery<CoursesTypesData>(COURSES_QUERY, {
    ssr: true,
  });

  const courses = useMemo(() => {
    if (!data) return [];
    return data.courses;
  }, [data]);

  return { courses, loading, error };
};

export default useCourses;
