import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Container, Typography, useMediaQuery } from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AccreditationsCard from "../cards/AccreditationsCard";
import useSliderNavigation from "../hooks/useSliderNavigation";
import SliderNavigation from "./SliderNavigation";
import renderHTML from "lib/render-html";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    heading: {
      "& span": {
        fontWeight: 500,
      },
    },
    slider: {
      "& .slick-slide > div": {
        margin: theme.spacing(4, 2),
        [theme.breakpoints.down("xs")]: {
          margin: theme.spacing(2, 0),
        },
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(3, 2),
        },
      },
      "& .slick-list": {
        margin: theme.spacing(0, -2),
        [theme.breakpoints.down("xs")]: {
          margin: theme.spacing(0),
        },
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, -2),
        },
      },
    },
  })
);

const staticData = {
  heading: "Our Accreditations with Leading Governing Bodies",
  logos: [
    {
      logo: "Agile-Marketing-Academy-Logo.svg",
      alt: "Agile-Marketing-Academy",
    },
    { logo: "APMG-Logo.svg", alt: "APMG-Logo" },
    { logo: "DevOps-Institute-Logo.svg", alt: "DevOps-Institute-Logo.svg" },
    { logo: "EXIN-Logo.svg", alt: "EXIN-Logo" },
    { logo: "ITIL-Logo.svg", alt: "ITIL-Logo" },
    { logo: "Microsoft-Partner-Logo.svg", alt: "Microsoft-Partner-Logo" },
    { logo: "PMI-Logo.svg", alt: "PMI-Logo" },
    { logo: "PRINCE2-Logo.svg", alt: "PRINCE2-Logo" },
    { logo: "Scaled-Agile-Logo.svg", alt: "Scaled-Agile-Logo" },
    { logo: "Scrum-Alliance-Logo.svg", alt: "Scrum-Alliance-Logo" },
    { logo: "Scrum.org-Logo.svg", alt: "Scrum.org-Logo" },
  ],
};

const OurAccreditationsSlider: React.FC<OurAccreditationsSliderProps> = ({
  content = staticData,
}) => {
  const classes = useStyles();
  const { sliderRef, next, previous } = useSliderNavigation();
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const settings = {
    className: classes.slider,
    infinite: true,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          initialSlide: 3,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          initialSlide: 2,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box className={classes.root} paddingY={[3, 4, 6, 8]} component="section">
      <Container>
        <Typography
          className={classes.heading}
          variant="h2"
          component="h3"
          align="center"
        >
          {renderHTML(content.heading)}
        </Typography>
        <Box paddingTop={[1, 2, 3, 4]}>
          <Slider {...settings} ref={sliderRef}>
            {content.logos
              .filter((item) => item.alt !== null)
              ?.map((logo, index) => (
                <AccreditationsCard
                  key={`accreditations-${index + 1}`}
                  logo={
                    process.env.NEXT_PUBLIC_ADMIN_URL +
                    "storage/images/" +
                    logo.logo
                  }
                  alt={logo.alt}
                />
              ))}
          </Slider>
          {xs && <SliderNavigation onNext={next} onPrevious={previous} />}
        </Box>
      </Container>
    </Box>
  );
};

export default OurAccreditationsSlider;