import React from "react";
import { IconButton } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import clsx from "clsx";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primary: {
      color: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    white: {
      color: theme.palette.common.white,
    },
  })
);
interface SliderNavigationProps {
  onNext: () => void;
  onPrevious: () => void;
  color?: "primary" | "secondary" | "white" | "error";
}

const SliderNavigation: React.FC<SliderNavigationProps> = ({
  onNext,
  onPrevious,
  color,
}) => {
  const classes = useStyles();
  return (
    <div>
      <IconButton onClick={onPrevious} size="small" title="Previous">
        <KeyboardBackspaceIcon
          fontSize="large"
          className={clsx(classes.primary, {
            [classes.primary]: color === "primary",
            [classes.secondary]: color === "secondary",
            [classes.error]: color === "error",
            [classes.white]: color === "white",
          })}
        />
      </IconButton>
      <IconButton onClick={onNext} size="small" title="Next">
        <KeyboardBackspaceIcon
          className={clsx(classes.primary, {
            [classes.primary]: color === "primary",
            [classes.secondary]: color === "secondary",
            [classes.error]: color === "error",
            [classes.white]: color === "white",
          })}
          style={{
            transform: "rotate(180deg)",
          }}
          fontSize="large"
        />
      </IconButton>
    </div>
  );
};

export default SliderNavigation;
