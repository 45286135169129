import { gql } from "@apollo/client";
const CONSTANT_VALUE_QUERY = gql`
  query GetConstantValues($constantName: String!) {
    constantValueByName(constantName: $constantName) {
      id
      name
      value
    }
  }
`;

export default CONSTANT_VALUE_QUERY;
