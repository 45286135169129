const mergeContents = (original, newData) => {
  let result = original.map((item) => {
    if (newData.hasOwnProperty(item.template.name)) {
      return {
        ...item,
        ["pagecontents"]: {
          ...item.pagecontents,
          ...newData[item.template.name].content,
        },
      };
    }
    return item;
  });
  return result;
};

export default mergeContents;
