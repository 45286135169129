import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
const useStyle = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));
const PrevArrow = (props) => {
  const { className, style, onClick, up } = props;
  const classes = useStyle();
  return (
    <KeyboardBackspaceIcon
      className={clsx(className, classes.root)}
      onClick={onClick}
      style={{ ...style, transform: up ? "rotate(90deg)" : "rotate(0deg)" }}
      fontSize="large"
    />
  );
};
export default PrevArrow;
