import React from "react";
import { Container } from "@material-ui/core";

const LoadComponent = (component, Components) => {
  // component does exist in Components object
  if (
    typeof Components[component.template.name.toLowerCase()] !== "undefined"
  ) {
    return React.createElement(
      Components[component.template.name.toLowerCase()],
      {
        key: component.template.name,
        content: component.pagecontents,
      }
    );
  }
  // component doesn't exist yet or not assign in the Components object
  return React.createElement(
    () => (
      <Container>
        <h1>
          {`The component "{component.template.name}" has not been created yet.`}
        </h1>
      </Container>
    ),
    { key: component.template.name.toLowerCase() }
  );
};

export default LoadComponent;
