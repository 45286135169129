import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Image from "next/image";
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: theme.spacing(11.25),
      display: "grid",
      placeContent: "center",
      position: "relative",
      boxShadow: "0px 8px 16px 0px rgb(0 0 0 / 15%)",
    },
  })
);

const myLoader = ({ src, width, quality }) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};

const AccreditationsCard: React.FC<AccreditationsCardProps> = ({
  alt,
  logo,
}) => {
  const classes = useStyle();
  return (
    <Paper className={classes.root} variant="outlined">
      <Image src={logo} alt={alt} fill loader={myLoader} loading="lazy" />
    </Paper>
  );
};

export default AccreditationsCard;
