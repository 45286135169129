import { gql } from "@apollo/client";

const COURSES_QUERY = gql`
  query GetCourses {
    courses {
      id
      name
    }
  }
`;

export default COURSES_QUERY;
