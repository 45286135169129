import { ApolloError, useQuery } from "@apollo/client";
import { useMemo } from "react";
import CONSTANT_VALUE_QUERY from "src/graphql/query/constantValueQuery";

interface ConstantValueTypes {
  id: string;
  name: string;
  value: string;
}
interface ConstantValuesTypes {
  constantValueByName: ConstantValueTypes[];
}

export default function useConstantValues(
  constantName: string
): [ConstantValueTypes[], { loading: boolean; error: ApolloError }] {
  const { data, error, loading } = useQuery<ConstantValuesTypes>(
    CONSTANT_VALUE_QUERY,
    {
      variables: {
        constantName: constantName,
      },
      ssr: true,
    }
  );

  const constantValues = useMemo(() => {
    if (!data) return [];
    return data.constantValueByName
      .map((item) => ({
        id: item.id,
        name: item.name,
        value: item.value,
      }))
      .reverse();
  }, [data]);

  return [constantValues, { loading, error }];
}

useConstantValues;
